import type { Claims, UserInfo } from '@elseu/sdu-titan-product-site-components';
import type { AuthenticationProviderProps } from '@elseu/sdu-titan-product-site-components/src/context/AuthenticationProvider/AuthenticationProvider';
import { useRouter } from 'next/router';
import { getSession, signIn, signOut, useSession } from 'next-auth/react';
import {
  useAuthAccessClaims,
  useAuthAccessToken,
  useAuthControls,
  useAuthInitialized,
  useAuthIsLoggedIn,
  useAuthSessionExpired,
  useAuthUserInfo,
} from 'oidc-jwt-client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { config } from '@/config';

const shouldForceAuthentication = config.featureFlags.includes('WITH_FORCED_AUTHENTICATION');
export const DEFAULT_AUTH_PROVIDER = 'oauth';
export const shouldUseServerSideAuthentication = config.authMode === 'server-side';

function useAuthInitializationClientSide(): AuthenticationProviderProps {
  const { value: claims, loading: isClaimsLoading } = useAuthAccessClaims<Claims>();
  const { value: userInfo, loading: isUserInfoLoading } = useAuthUserInfo<UserInfo>();
  const getAccessToken = useAuthAccessToken();
  const { authorize, logout } = useAuthControls();
  const isInitialized = useAuthInitialized();
  const isSessionExpired = useAuthSessionExpired();
  const isLoggedIn = useAuthIsLoggedIn();

  return {
    getAccessToken,
    userInfo: userInfo ?? null,
    isLoading: isUserInfoLoading || isClaimsLoading,
    isInitialized,
    isSessionExpired,
    isLoggedIn,
    logout,
    authorize,
    claims: claims ?? null,
  };
}

const SILENT_AUTH_KEY = 'silent-auth';
const SILENT_AUTH_EXPIRY = 10 * 1000; // 6 hours

function useSilentAuth(status: 'loading' | 'unauthenticated' | 'authenticated') {
  const [silentAuthTimestamp, setSilentAuthTimestamp, clearSilentAuthTimestamp] =
    useLocalStorage<number>(SILENT_AUTH_KEY, undefined, {
      raw: false,
      serializer: (value) => value.toString(),
      deserializer: (value) => Number(value),
    });
  const [isLoading, setLoading] = useState(() => status === 'unauthenticated');

  const hasCompletedSilentSignin =
    !!silentAuthTimestamp &&
    typeof silentAuthTimestamp === 'number' &&
    Date.now() - silentAuthTimestamp < SILENT_AUTH_EXPIRY;

  useEffect(() => {
    if (hasCompletedSilentSignin && status === 'authenticated') {
      clearSilentAuthTimestamp();
    }

    if (status === 'unauthenticated') {
      if (!hasCompletedSilentSignin) {
        setLoading(true);
        signIn(
          DEFAULT_AUTH_PROVIDER,
          {},
          {
            prompt: 'none',
          },
        )
          .catch(() => {
            setLoading(false);
          })
          .finally(() => {
            setSilentAuthTimestamp(Date.now());
          });
      }
    }
  }, [clearSilentAuthTimestamp, hasCompletedSilentSignin, setSilentAuthTimestamp, status]);

  return { isLoading: status !== 'authenticated' && isLoading };
}

export function useAuthInitializationServerSide(): AuthenticationProviderProps {
  const router = useRouter();

  const params = router.query;

  const { data: session, status } = useSession({
    required: shouldForceAuthentication || params.forceAuth === 'true',
    onUnauthenticated() {
      signIn(DEFAULT_AUTH_PROVIDER, undefined, {
        ...(params.idp && { fed_idp: params.idp as string }),
      });
    },
  });

  const { isLoading } = useSilentAuth(status);

  const isTokenExpired = !session?.claims || session.claims.exp * 1000 < Date.now();
  const accessToken = session?.accessToken ?? null;

  useEffect(() => {
    if (session?.error === 'RefreshAccessTokenError') {
      signIn(DEFAULT_AUTH_PROVIDER); // Force sign in to hopefully resolve error
    }
  }, [session]);

  const getAccessToken = useCallback(async () => {
    if (isTokenExpired) {
      const newSession = await getSession();
      return newSession?.accessToken ?? null;
    }

    return accessToken;
  }, [accessToken, isTokenExpired]);

  return useMemo(() => {
    return {
      getAccessToken,
      isSessionExpired: false,
      authorize: (params) => {
        signIn(DEFAULT_AUTH_PROVIDER, undefined, params);
      },
      claims: session?.claims ?? null,
      isInitialized: status !== 'loading' && !isLoading,
      isLoading: status === 'loading' || isLoading,
      isLoggedIn: status === 'authenticated',
      userInfo: session?.userInfo ?? null,
      logout: () => {
        const searchParams = new URLSearchParams({
          id_token_hint: session?.idToken as string,
          previous_page: window.location.pathname,
        });

        signOut({
          // logic to redirect to the correct page after logout
          callbackUrl: `signOut?id_token_hint=${searchParams.get('id_token_hint')}&previous_page=${searchParams.get('previous_page')}`,
        });
      },
    };
  }, [getAccessToken, isLoading, session?.claims, session?.idToken, session?.userInfo, status]);
}

export const useAuthInitialization = shouldUseServerSideAuthentication
  ? useAuthInitializationServerSide
  : useAuthInitializationClientSide;
